import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["video"]
  static values = {
    url: String
  }

  connect() {
    // Wait for the video element to be ready
    if (this.element.tagName === 'VIDEO') {
      this.setupVideo(this.element)
    }
  }

  setupVideo(videoElement) {
    if (!videoElement) return

    // Add event listeners
    videoElement.addEventListener('loadedmetadata', () => {
      this.playVideo(videoElement)
    })

    videoElement.addEventListener('error', (e) => {
      console.error('Video error:', e)
      this.handleVideoError(videoElement)
    })

    // Try initial play
    if (videoElement.readyState >= 2) { // HAVE_CURRENT_DATA or better
      this.playVideo(videoElement)
    }
  }

  playVideo(videoElement) {
    if (!videoElement || typeof videoElement.play !== 'function') {
      console.warn('Video element not ready')
      return
    }

    try {
      const playPromise = videoElement.play()

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log('Video started playing automatically')
          })
          .catch(error => {
            console.warn('Autoplay was prevented:', error)
            // Don't retry on user interaction errors
            if (!error.name.includes('NotAllowed')) {
              this.handleVideoError(videoElement)
            }
          })
      }
    } catch (error) {
      console.error('Error playing video:', error)
    }
  }

  handleVideoError(videoElement) {
    if (!videoElement) return

    // If HLS stream fails, try falling back to MP4
    const currentSrc = videoElement.currentSrc || ''
    if (currentSrc.includes('m3u8')) {
      console.log('Falling back to MP4 stream')
      // Find the MP4 source
      const mp4Source = Array.from(videoElement.getElementsByTagName('source'))
        .find(source => source.type === 'video/mp4')
      
      if (mp4Source) {
        videoElement.src = mp4Source.src
        videoElement.load() // Important: reload the video
        this.playVideo(videoElement)
      }
    }
  }
}
