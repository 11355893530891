import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["container", "button"]
  static values = {
    auto: Boolean,
    delay: Number
  }

  connect() {
    this.isFullscreen = false
    this.updateButtonIcon()

    // Set up auto fullscreen timer if enabled
    if (this.autoValue) {
      setTimeout(() => {
        if (!this.isFullscreen) {
          this.enterFullscreen()
        }
      }, this.delayValue || 30000) // Default to 30 seconds
    }

    // Handle fullscreen change events
    document.addEventListener('fullscreenchange', () => {
      this.isFullscreen = !!document.fullscreenElement
      this.updateButtonIcon()
    })
  }

  toggle() {
    if (this.isFullscreen) {
      this.exitFullscreen()
    } else {
      this.enterFullscreen()
    }
  }

  async enterFullscreen() {
    try {
      if (this.containerTarget.requestFullscreen) {
        await this.containerTarget.requestFullscreen()
      } else if (this.containerTarget.webkitRequestFullscreen) {
        await this.containerTarget.webkitRequestFullscreen()
      } else if (this.containerTarget.msRequestFullscreen) {
        await this.containerTarget.msRequestFullscreen()
      }
    } catch (error) {
      console.error('Error attempting to enable fullscreen:', error)
    }
  }

  exitFullscreen() {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    } catch (error) {
      console.error('Error attempting to exit fullscreen:', error)
    }
  }

  updateButtonIcon() {
    const icon = this.buttonTarget.querySelector('i')
    if (icon) {
      icon.className = this.isFullscreen ? 'mdi mdi-fullscreen-exit' : 'mdi mdi-fullscreen'
    }
  }
}
