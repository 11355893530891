import { Controller } from "@hotwired/stimulus";
// import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    this.toast = new bootstrap.Toast(this.element);
    this.toast.show();

    // Remove the toast from DOM after it's hidden
    this.element.addEventListener("hidden.bs.toast", () => {
      this.element.remove();
    });
  }

  dismiss(event) {
    // Allow the default navigation to proceed
    // The toast will be removed after navigation due to Turbo
    if (this.toast) {
      this.toast.hide();
    }
  }
}
