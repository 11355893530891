// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./libs/jquery";
import "./controllers";
import * as bootstrap from "bootstrap";
import * as echarts from "echarts";
import "echarts/theme/dark";

window.echarts = echarts;
window.bootstrap = bootstrap;

$(document).on("turbo:load", () => {
  $('button[data-bs-toggle="tab"]').on("shown.bs.tab", function (e) {
    const targetTab = $(e.target).data("bs-target").replace("#", "");
    console.log(targetTab);
    if (history.pushState) {
      let currentState = history.state;
      history.pushState(currentState, null, `?tab=${targetTab}`);
    } else {
      location.query = `?tab=${targetTab}`;
    }
  });
});
